<template>
  <div class="result">
    <p :class="['icon', resultIcon]"></p>
    <div class="container">
      <template v-if="status === 'success'">
        <h1 class="count">{{ $tc('rechargeResult.success', orderFee) }}</h1>
        <h2 class="amount">{{ $tc('rechargeResult.anount', balance) }}</h2>
        <div class="footer">
          <mi-button @click="goFrontPage()">{{ $t('common.done') }}</mi-button>
        </div>
      </template>

      <template v-else-if="status === 'wait'">
        <h1 class="count">{{ $t('rechargeResult.waitTitle') }}</h1>
        <h2 class="amount">{{ $t('rechargeResult.waitDesc') }}</h2>
        <div class="footer">
          <mi-button @click="goFrontPage()">
            {{ $t('paymentResult.goMibi') }}
          </mi-button>
        </div>
      </template>

      <template v-else>
        <h1 class="count">{{ $t('rechargeResult.failed') }}</h1>
        <h2 class="amount">{{ errDesc }}</h2>
        <div class="footer">
          <router-link to="/recharge">
            <mi-button>{{ $t('common.retry') }}</mi-button>
          </router-link>
          <router-link to="/" replace>
            <div class="back">{{ $t('common.back') }}</div>
          </router-link>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { MiButton } from '@/components'
import { unitFormat } from '@/utils'

export default {
  components: {
    MiButton
  },
  data() {
    const { status, errDesc, balance, orderFee } = this.$route.query

    return {
      status,
      errDesc,
      balance: unitFormat(+balance),
      orderFee: unitFormat(+orderFee)
    }
  },
  computed: {
    resultIcon() {
      return this.status === 'success' ? 'success' : 'failed'
    }
  },
  methods: {
    goFrontPage() {
      this.$router.replace('/')
    }
  }
}
</script>

<style lang="scss" scoped>
.result {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 60px 80px 90px;
  .icon {
    width: 90px;
    height: 90px;
    margin: 0 auto 27px;
  }
  .success {
    @include level-bg-image('success');
  }
  .failed {
    @include level-bg-image('failed');
  }
  .container {
    flex: 1;
    position: relative;
    text-align: center;
    .count {
      font-weight: 500;
      font-size: 72px;
      color: #000;
    }
    .amount {
      font-size: 38px;
      color: rgba(0, 0, 0, 0.5);
      margin-top: 27px;
    }
    .footer {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      .back {
        height: 140px;
        background: rgba(0, 0, 0, 0.06);
        @include round(140px);
        text-align: center;
        line-height: 140px;
        font-weight: 500;
        font-size: 48px;
        margin-top: 50px;
      }
    }
  }
}
</style>
