<template>
  <div class="recharge">
    <MiTitle :title="$t('recharge.title')" />
    <div class="main">
      <h2 class="title">{{ $t('recharge.payType') }}</h2>
      <ul class="pay">
        <li
          v-for="{ id, name, desc } in rechargeTypeInfo.up"
          :key="id"
          @click="rechargeMoney('money', id)"
        >
          <span class="left">
            <em :class="['icon', id]"></em>
            {{ name }}
          </span>
          <span class="right">
            <em v-if="desc">{{ desc }}</em>
            <em class="arrow"></em>
          </span>
        </li>
      </ul>
      <p class="line"></p>
      <h2 class="title">{{ $t('recharge.otherPay') }}</h2>
      <ul class="pay">
        <li
          v-for="{ id, name, desc } in rechargeTypeInfo.down"
          :key="id"
          @click="rechargeMoney('card', id)"
        >
          <span class="left"><em :class="['icon', id]"></em>{{ name }}</span>
          <span class="right">
            <em v-if="desc">{{ desc }}</em>
            <em class="arrow"></em>
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { checkPrivacy } from '@/mixins'
import { mapState } from 'vuex'
import { MiTitle } from '@/components'

export default {
  mixins: [checkPrivacy],
  components: {
    MiTitle
  },
  computed: {
    ...mapState({
      userInfo: state => state.about.userInfo,
      rechargeTypeInfo: state => state.recharge.rechargeTypeInfo
    })
  },
  created() {
    if (!this.userInfo.userId) {
      this.$store.dispatch('getHomeInfo')
    }

    this.$store.dispatch('rechargeGetTypes')
  },
  methods: {
    rechargeMoney(type, id) {
      let url = ''

      if (type === 'money') {
        url = `/money?type=${id}`
      } else if (id === 'phonecard') {
        url = `/phone`
      } else if (id === 'voucher') {
        url = `/mibi`
      }
      this.$router.push(url)
    }
  }
}
</script>

<style lang="scss" scoped>
.recharge {
  .main {
    padding: 90px 80px;
    .title {
      font-size: 36px;
      color: #8c93b0;
    }
    .pay {
      margin-top: 24px;
      li {
        height: 170px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:active {
          opacity: 0.8;
        }
        .left {
          display: flex;
          align-items: center;
          font-size: 48px;
          font-weight: 500;
          color: $theme-text-color;
          .icon {
            width: 110px;
            height: 110px;
            margin-right: 45px;
          }
          .wxpay {
            @include level-bg-image('wxpay');
          }
          .alipay {
            @include level-bg-image('alipay');
          }
          .mipay {
            @include level-bg-image('mipay');
          }
          .paypal {
            @include level-bg-image('paypal');
          }
          .phonecard {
            @include bg-image('phonecard');
          }
          .voucher {
            @include bg-image('voucher');
          }
        }
        .right {
          font-size: 42px;
          color: $color-text-warn;
          display: flex;
          align-items: center;
          .arrow {
            width: 26px;
            height: 42px;
            @include level-bg-image('arrow');
            margin-left: 24px;
          }
        }
      }
    }
    .line {
      margin: 62px 0 84px;
      height: 1px;
      background-color: $color-split-line;
    }
  }
}
</style>
